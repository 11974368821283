import { keys } from 'lodash';
import componentShapes from '../utility/componentShapes';

export default {
  props: {
    shape: {
      type: String,
      default: componentShapes.square.name,
      required: false,
      validator(value) {
        return keys(componentShapes).indexOf(value) > -1;
      },
    },
  },
  computed: {
    shapeClass() {
      return `shape-${this.shape}`;
    },
  },
};
