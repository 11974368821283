<template>
  <div class="landscape-notification-wrapper">
    <div class="landscape-notification">
      <div class="notification-icon"
        :style="{ backgroundImage: `url(assets/portraitPhoneIcon.png)` }"></div>
      <div class="notification-message">
        {{ switchLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandscapeNotification',
  computed: {
    ...mapGetters([
      'translations',
    ]),
    switchLabel() {
      return this.translations.portraitModeSwitchMessage;
    },
  },
};
</script>
<style lang="scss" scoped>
.landscape-notification-wrapper {
  display: none;
  @media screen and (orientation: landscape) and (max-width: 1400px) and (hover: none) {
    display: block;
  }
  .landscape-notification {
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: #233c50;
    background-image: linear-gradient(
      225deg,
      #46384d 0%,
      #2d3449 49.82%,
      #233c50 100%
    );
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}
.notification-icon {
  width: 85px;
  height: 70px;
  background-repeat: no-repeat;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.notification-message {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: #fff;
  width: 80%;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
}
</style>
