export default {
  activeComponent: state => state.activeComponent,
  activeTicketType: state => state.activeTicketType,
  activeTicketTypes: (state, getters) => getters.internalTicketTypes
    .filter(type => getters.config.ticketTypes.includes(type.value)),
  activeBetslipInput: state => state.activeBetslipInput,
  betLayout: (state, getters) => getters.config.betLayout,
  betslipBlockers: state => state.betslipBlockers,
  config: state => state.config,
  combinationMinPayment:
    (state, getters) => combinations => getters.rules.minCombBetAmount.value * combinations,
  internalTicketTypes: (state, getters, rootGetters) => [
    {
      id: 0,
      key: 0,
      value: 'single',
      label: rootGetters.translations.translations.general_ticket_single,
      active: true,
    },
    {
      id: 1,
      key: 1,
      value: 'combo',
      label: rootGetters.translations.translations.general_ticket_combo,
      active: true,
    },
    {
      id: 2,
      key: 2,
      value: 'system',
      label: rootGetters.translations.translations.general_ticket_system,
      active: false,
      disabled: true,
    },
  ],
  futureRounds: state => state.futureRounds,
  formatNumber: () => number => Number(number.toFixed(2)),
  decimalFormatNumber: () => number => Number(number).toFixed(2),
  hasTicketLockedBets: (state, getters) => getters.ticket
    .some(bet => bet.locked && bet.permanentlyLock),
  hasMultipleTicketTypes: (state, getters) => getters.activeTicketTypes.length > 1,
  isAddButtonActive: (state, getters) => getters.config.isAddButtonActive,
  isTicketHistoryActive: state => state.activeComponent.name === 'TicketHistory',
  isBetslipActive: state => state.activeComponent.name === 'Betslip',
  invalidBetPayments: (state, getters) => getters.ticket.filter(bet => !bet.isValid),
  isPaymentPerBetActive: (state, getters) => Number(state.paymentPerBet)
    > getters.config.rules.minBetAmount.value || (Number(state.paymentPerBet) === 0 && state.paymentPerBet !== ''),
  isManualMode: state => state.isManualMode,
  isPayinInProgress: state => state.isPayinInProgress,
  isLastTicketsInProgress: state => state.isLastTicketsInProgress,
  isQuickpayEnabled: state => state.isQuickpayEnabled,
  isPayinButtonDisabled: state => state.isPayinButtonDisabled,
  isPaymentPerBetValid: state => state.isPaymentPerBetValid,
  isStakeBoxVisible: state => state.isStakeBoxVisible,
  isBetAppearAnimationDisabled: state => state.isBetAppearAnimationDisabled,
  isTotalPaymentValid: state => state.isTotalPaymentValid,
  isPaymentValid: state => state.isPaymentValid,
  isCombo: (state, getters) => getters.activeTicketType.value === 'combo',
  isSingle: (state, getters) => getters.activeTicketType.value === 'single',
  isCantSplitActive: state => state.isCantSplitActive,
  isUserBalanceInvalid: state => state.isUserBalanceInvalid,
  minBetPayment: state => state.minBetPayment,
  maxBetPayment: (state, getters) => getters.rules.maxBetAmount.value,
  maxBetNumber: (state, getters) => getters.rules.maxBetNumber.value,
  minBetPaymentForBet: (state, getters) => {
    if (getters.isPaymentPerBetActive) {
      return getters.paymentPerBet;
    }

    return getters.minBetPayment;
  },
  minTicketPayment: state => state.minTicketPayment,
  minPaymentAllowed: state => state.minPaymentAllowed,
  maxPaymentAllowed: state => state.maxPaymentAllowed,
  mobileAnimationHeight: () => 'calc(100vh - var(--footer-height))',
  mobileBetslipHeight: (state, getters, rootGetters) => {
    // 176 - html elements height that should be removed from full height
    if (rootGetters.isMobile) {
      return `calc(100vh - 136px - var(--footer-height) - ${getters.hasMultipleTicketTypes ? 48 : 0}px)`;
    }

    // Default height
    return '352px';
  },
  mobileTicketHeight: (state, getters, rootGetters) => {
    // 176 - html elements height that should be removed from full height
    if (rootGetters.isMobile) {
      return `calc(100vh - 136px - var(--footer-height) - ${getters.hasMultipleTicketTypes ? 48 : 0}px - 2px)`;
    }

    // Default height
    return '352px';
  },
  mobileLastTicketsHeight: (state, getters, rootGetters) => {
    // 216 - html elements height that should be removed from full height
    if (rootGetters.isMobile) {
      return 'calc(100vh - 176px)';
    }

    // Default height
    return '464px';
  },
  terminalBetslipHeight(state, getters) {
    return `calc(100vh - ${getters.hasMultipleTicketTypes ? 40 : 0}px - var(--footer-height))`;
  },
  multiplyWithoutFloatIssues: () => (a, b) => {
    const precision = 1e10;
    const result = (a * precision) * (b * precision) / (precision * precision);
    return result;
  },
  numberOfBets: (state, getters) => getters.ticket?.length,
  rules: (state, getters) => getters.config.rules,
  roundFloatNumber: () => number => Math.round(number * 100) / 100,
  roundNumber: () => number => number.toFixed(2),
  paymentPerBet: (state, getters) => {
    if (state.stakePerBet === '') return '';

    if (Number(state.stakePerBet) <= 0) {
      return getters.rules.minBetAmount.value;
    }

    return state.paymentPerBet;
  },
  preBet: state => state.preBet,
  playerTickets: state => state.playerTickets,
  possibleWin: state => state.possibleWin,
  possibleWinPreBet: state => state.possibleWinPreBet,
  possibleWinCombo: state => state.possibleWinCombo,
  selectedBet: state => state.preBet.bet,
  totalPayment: state => state.totalPayment,
  ticket: state => state.ticket,
  totalOdds: state => state.totalOdds,
  taxes: (state, getters) => getters.config.taxes,
  isFuturePerTicketAllowed: (state, getters) => getters.config.isFuturePerTicketAllowed,
  isPaymentPerBetAllowed: (state, getters) => getters.config.isPaymentPerBetAllowed,
  retailTicketHeight: (state, getters) => `calc(100vh - ${getters.hasMultipleTicketTypes ? 40 : 0}px - 35px - var(--footer-height))`,
  showNotification: (state, getters) => getters.config.showNotification,
  channelType: state => state.config.channelType,
  isPayinDisabled: (state, getters, rootState, rootGetters) => getters.isPayinButtonDisabled
    || getters.numberOfBets <= 0
    || !getters.isTotalPaymentValid
    || !getters.isPaymentValid
    || getters.isPayinInProgress
    || getters.isCantSplitActive
    || getters.invalidBetPayments.length
    || getters.hasTicketLockedBets
    || rootGetters.isBettingDisabled,
  betsHaveFutureRounds: state => state.ticket.some(bet => bet.numEvents > 1),
};
