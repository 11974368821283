import { auth } from '../../../api';
import types from './mutationTypes';
import notificationTypes from '../notifications/notificationTypes';

let manualBalanceUpdateTimeout = null;

export default {
  updateUser({
    commit,
    getters,
    dispatch,
    rootGetters,
  }, payload) {
    commit(types.UPDATE_USER, payload);

    if (getters.isLoggedIn) {
      dispatch('notifications/clearNotification', {
        type: notificationTypes.USER_LOGIN,
      }, { root: true });
    } else {
      dispatch('notifications/setNotification', {
        type: notificationTypes.USER_LOGIN,
        message: rootGetters.translations.general_player_login,
        status: 'negative',
        notificationTimeout: true,
      }, { root: true });
    }
  },
  // we should call this only if 3rd party integration don't send balance value
  getUserBalance({ rootGetters, dispatch }) {
    dispatch('clearManualBalanceTimeout');
    try {
      const { token } = rootGetters['user/user'].auth;
      const { config } = rootGetters;
      auth.getBalance(config, token).then((response) => {
        if (response) {
          dispatch('setUserBalance', response.balance, { root: true });
          dispatch('setManualBalanceTimeout');
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error);
    }
  },
  setManualBalanceTimeout({ rootGetters, dispatch }) {
    const { manualBalanceTimeoutValue } = rootGetters.config.ui.config;
    manualBalanceUpdateTimeout = setTimeout(() => {
      dispatch('getUserBalance');
    }, manualBalanceTimeoutValue);
  },
  clearManualBalanceTimeout() {
    clearTimeout(manualBalanceUpdateTimeout);
  },
  setUserBalance({ commit }, payload) {
    commit(types.SET_USER_BALANCE, payload);
  },
  setTerminalUser({ commit }, payload) {
    commit(types.SET_TERMINAL_USER, payload);
  },
  setUserBonuses({ commit }, payload) {
    commit(types.SET_USER_BONUSES, payload);
  },
};
