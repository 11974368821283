export default {
  REGISTER_CLIENT_APP: 'REGISTER_CLIENT_APP',
  SET_FULL_SCREEN_STATUS: 'SET_FULL_SCREEN_STATUS',
  SET_STREAM_UUID: 'SET_STREAM_UUID',
  TOGGLE_AUDIO: 'TOGGLE_AUDIO',
  SET_IS_MOBILE_DEVICE_LAYOUT: 'SET_IS_MOBILE_DEVICE_LAYOUT',
  SET_IS_TABLET_DEVICE_LAYOUT: 'SET_IS_TABLET_DEVICE_LAYOUT',
  SET_IS_DESKTOP_DEVICE_LAYOUT: 'SET_IS_DESKTOP_DEVICE_LAYOUT',
  SET_ODD_TYPE: 'SET_ODD_TYPE',
};
