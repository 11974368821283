import {
  filter,
  merge,
  shuffle,
  sampleSize,
  range,
  omit,
  map,
  each,
  findIndex,
  every,
  has,
} from 'lodash';
import {
  statistics,
  results,
  bonus,
  auth,
} from '@nsftx/games-sdk-js';
import mutationTypes from '@/store/mutationTypes';
import api from '@/api';

export default {
  setConfig({ commit }, config) {
    commit(mutationTypes.SET_CONFIG, config);
  },
  setGameState({ commit }, gameState) {
    commit(mutationTypes.SET_GAME_STATE, gameState);
  },
  setApplicationMode({ commit }, mode) {
    commit(mutationTypes.SET_APPLICATION_MODE, mode);
  },
  updateGameState({ commit }, gameState) {
    commit(mutationTypes.UPDATE_GAME_STATE, gameState);
  },
  setDeviceType({ commit }, device) {
    commit(mutationTypes.SET_DEVICE_TYPE, device);
  },
  toggleBetNumbers({ getters, commit, dispatch }, betNumbers) {
    commit('TOGGLE_BET_NUMBERS', betNumbers);
    dispatch('resetSpecials');
    dispatch('resetFirstBallColor');

    if (betNumbers.length > 1) {
      dispatch('resetPreballBet');
      if (betNumbers.length > getters.minBallSelection) {
        dispatch('resetSelectedColor');
      }
    }

    if (betNumbers.length) {
      const isColorBet = every(betNumbers, ['hex', betNumbers[0].hex]) && betNumbers.length === 6;
      if (isColorBet) {
        const colorGroup = {
          color: betNumbers[0].hex,
          checked: true,
        };
        dispatch('toggleBetNumbersByColor', colorGroup);
      } else {
        dispatch('resetSelectedColor');
      }
    }
  },
  toggleColorss({ commit }, colorGroup) {
    commit(mutationTypes.TOGGLE_COLORSS, colorGroup);
  },
  toggleBetNumbersByColor({ dispatch, getters }, colorGroup) {
    let selectedBetNumbersByColor = [];
    if (colorGroup.checked) {
      selectedBetNumbersByColor = filter(
        getters.bingoNumbers,
        (number) => number.hex === colorGroup.color,
      );
    }
    if (has(colorGroup, 'key')) {
      dispatch('toggleBetNumbers', selectedBetNumbersByColor);
    }
    dispatch('toggleColorss', colorGroup);
  },
  togglePreballBet({ getters, dispatch, commit }, active) {
    commit(mutationTypes.TOGGLE_PREBALL_BET, active);
    if (!active) {
      dispatch('toggleBetNumbers', getters.selectedBetNumbers);
    }
    dispatch('resetSpecials');
    dispatch('resetSelectedColor');
    dispatch('resetFirstBallColor');
  },
  toggleSpecialBets({ dispatch, commit }, { specialBet, outcomeIndex }) {
    commit(mutationTypes.TOGGLE_SPECIAL_BETS, { specialBet, outcomeIndex });
    dispatch('resetSelectedBetNumbers');
    dispatch('resetSelectedColor');
    dispatch('resetFirstBallColor');
    dispatch('resetPreballBet');
  },
  toggleFirstBallColor({ dispatch, commit }, { bet, outcome }) {
    commit(mutationTypes.TOGGLE_FIRST_BALL_COLOR, { bet, outcome });
    dispatch('resetSpecials');
    dispatch('resetSelectedBetNumbers');
    dispatch('resetSelectedColor');
    dispatch('resetPreballBet');
  },
  resetSelectedBetNumbers({ commit }) {
    commit(mutationTypes.RESET_SELECTED_BET_NUMBERS);
  },
  resetPreballBet({ commit }) {
    commit(mutationTypes.RESET_PREBALL_BET);
  },
  resetSelectedColor({ commit }) {
    commit(mutationTypes.RESET_SELECTED_COLOR);
  },
  resetSpecials({ commit }) {
    commit(mutationTypes.RESET_SPECIALS);
  },
  resetFirstBallColor({ commit }) {
    commit(mutationTypes.RESET_FIRST_BALL_COLOR);
  },
  quickPick({ state, dispatch, getters }, pick) {
    const numbersRange = range(state.minBingoNumber, state.maxBingoNumber + 1);
    const numbersShuffle = shuffle(numbersRange);
    const numbersSample = sampleSize(numbersShuffle, pick.label);
    const filteredNumbers = filter(
      getters.bingoNumbers,
      (number) => numbersSample.includes(number.value),
    );
    dispatch('toggleBetNumbers', filteredNumbers);
  },
  clearSelection({ dispatch, commit }) {
    dispatch('resetSelectedBetNumbers');
    dispatch('resetPreballBet');
    dispatch('resetSelectedColor');
    dispatch('resetSpecials');
    dispatch('resetFirstBallColor');
    commit(mutationTypes.CLEAR_SELECTED_BET);
  },
  setUserBalance({ dispatch, getters }, payload) {
    if (getters.isTerminal) {
      dispatch('user/setUserBalance', payload, { root: true });
    }
    if (!getters.isTerminal) {
      const divider = getters.config.ui.config.balanceDivider;
      const balance = divider ? payload / divider : payload;
      dispatch('user/setUserBalance', balance, { root: true });
    }
  },
  setUserData({ dispatch }, payload) {
    let user = {};
    if (payload.auth) {
      user = {
        auth: omit(payload.auth, 'user'),
        profile: payload.auth.user || payload.user,
      };
    } else {
      user = {
        auth: {
          token: payload.token,
          tpToken: payload.tpToken,
        },
        profile: omit(payload, ['token', 'tokenType']),
      };
    }
    dispatch('user/updateUser', user, { root: true });
  },
  setTerminalUserData({ commit }, payload) {
    commit(mutationTypes.SET_TERMINAL_USER_DATA, payload);
  },
  getResults({ dispatch, getters }) {
    const user = getters['user/user'];
    results.getResults(getters.config, user).then((resultsResponse) => {
      dispatch('setResults', resultsResponse.data);
    });
  },
  getStats({ dispatch, getters }) {
    const user = getters['user/user'];
    statistics.getStatistics(getters.config, user).then((stats) => {
      dispatch('setStats', stats.data);
    });
  },
  setResults({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_RESULTS, data);
  },
  setStats({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_STATS, data);
  },
  toggleModalInfo({ commit }) {
    commit(mutationTypes.TOGGLE_MODAL_INFO);
  },
  formatPlayerTickets(state, payload) {
    const resolveOutcome = (bet) => {
      if (bet.type === 5) {
        const colors = [];
        let colorsString = '';
        let colorIndex = '';
        each([0, 1, 2, 3, 4, 5, 6, 7, 8], (n) => {
          if (parseInt(bet.value, 10) & 2 ** n) {
            colorIndex = (n === 0) ? 7 : n - 1;
            colors.push(state.getters.firstBallColor.outcomes[colorIndex]);
            colorsString = colors.join(' ');
          }
        });
        return colorsString;
      }
      if (bet.type > 5 && bet.type !== 10) {
        const specialBetIndex = findIndex(state.getters.specials, { id: bet.type });
        return state.getters.specials[specialBetIndex].outcomes[bet.value];
      }
      return bet.value;
    };
    return map(payload, (tickets) => {
      let bets = [];
      bets = map(tickets.bets, (bet) => ({
        id: bet.id,
        status: bet.status,
        round: bet.eventId,
        market: bet.typeValue,
        outcome: resolveOutcome(bet),
        outcomeIndex: bet.type >= 5 ? bet.value : null,
        stake: bet.amount,
        odd: bet.odd,
        eventValue: bet.eventValue,
        type: bet.type,
        tax: bet.tax,
        winnings: bet.winnings,
        category: bet.category,
        system: bet.system || null,
      }));
      return {
        id: tickets.id,
        payout: tickets.payout,
        payin: tickets.payin,
        payinTax: tickets.payinTax,
        payoutTax: tickets.payoutTax,
        superBonus: tickets.superBonus,
        createdAt: tickets.createdAt,
        status: tickets.status,
        maxPossibleWin: tickets.maxPossibleWin,
        bonuses: tickets.bonuses,
        product: tickets.product,
        bets,
      };
    });
  },
  updateRound({ commit }, round) {
    commit(mutationTypes.UPDATE_ROUND, round);
  },
  bettingDisable({ commit }, disable) {
    commit(mutationTypes.BETTING_DISABLE, disable);
  },
  setTranslations({ commit }, translations) {
    commit(mutationTypes.SET_TRANSLATIONS, translations);
  },
  setDrawnBalls({ commit }, payload) {
    commit(mutationTypes.SET_DRAWN_BALLS, payload);
  },
  toggleNewFeatures({ commit }) {
    commit(mutationTypes.TOGGLE_NEW_FEATURES);
  },
  async getPlayer({ dispatch }, { config, token }) {
    try {
      const player = await auth.authenticate(config, token);
      const isLoggedIn = !!player.uuid;
      const userData = {
        auth: {
          token: player.auth.token,
          tpToken: player.auth.thirdPartyToken || null,
        },
        profile: merge({
          balance: player.balance,
          balanceTotal: player.balance,
          logged: isLoggedIn,
          id: player.uuid,
        }),
      };
      if (isLoggedIn) {
        dispatch('user/updateUser', userData);
        dispatch('setUserBalance', player.balance);
      }
    } catch (error) {
      dispatch('setError', {
        name: 'InvalidCredentialsError',
      });
    }
  },
  authUser({ dispatch }, {
    config,
    token,
    playerId,
    clientType,
  }) {
    api.authSevenUser(config, token, playerId, clientType).then((response) => {
      if (response) {
        const currentToken = response.headers['access-token'];
        Promise.all([
          api.sevenLoginCheck(config, currentToken),
          api.getSevenBalance(config, currentToken, clientType),
        ]).then((responses) => {
          const isLoggedIn = !!responses[0].data.firstName;
          const userData = {
            auth: {
              token: currentToken,
              tpToken: token || null,
            },
            profile: merge(responses[0].data, {
              balance: responses[1].data.balance,
              balanceTotal: responses[1].data.balance,
              logged: isLoggedIn,
              id: responses[0].data.Uuid,
            }),
          };
          dispatch('setUserData', userData);
          if (isLoggedIn) {
            dispatch('user/updateUser', userData);
            dispatch('setUserBalance', responses[1].data.balance);
          }
        });
      }
    });
  },
  setThirdPartyToken({ commit }, token) {
    commit(mutationTypes.SET_THIRD_PARTY_TOKEN, token);
  },
  setIsMobile({ commit }, isMobile) {
    commit(mutationTypes.SET_IS_MOBILE, isMobile);
  },
  setIsTouchDevice({ commit }, isTouchDevice) {
    commit(mutationTypes.SET_IS_TOUCH_DEVICE, isTouchDevice);
  },
  setIsBetslipOpen({ commit }, isBetslipOpen) {
    commit(mutationTypes.SET_IS_BETSLIP_OPEN, isBetslipOpen);
  },
  async setJackpotState({ commit, getters }) {
    const jackpot = await api.getJackpot(getters.config);

    if (jackpot.jackpotId) {
      commit(mutationTypes.SET_JACKPOT_STATE, jackpot);
    }
  },
  toggleBonusModal({ commit }, show) {
    commit(mutationTypes.TOGGLE_BONUS_MODAL, show);
  },
  async getUserBonuses({ dispatch, getters }) {
    const user = getters['user/user'];
    const { config } = getters;
    const data = await bonus.getBonuses(config, user);
    dispatch('user/setUserBonuses', data);
  },
  isFreeBetMode({ commit }, isFreeBet) {
    commit(mutationTypes.IS_FREE_BET_MODE, isFreeBet);
  },
  setGameChannelType({ commit }, channelType) {
    commit(mutationTypes.SET_GAME_CHANNEL_TYPE, channelType);
  },
  setIsTerminal({ commit }, isTerminal) {
    commit(mutationTypes.SET_IS_TERMINAL, isTerminal);
  },
  setCountdownData({ commit }, time) {
    commit(mutationTypes.SET_COUNTDOWN_DATA, time);
  },
  setActiveTerminalLayout({ commit }, layout) {
    commit(mutationTypes.SET_ACTIVE_TERMINAL_LAYOUT, layout);
  },
  setTicketHistoryTerminal({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_HISTORY_TERMINAL, payload);
  },
  setTicketDetailsData({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_DETAILS_DATA, payload);
  },
  setLoaded({ commit }, data) {
    commit(mutationTypes.SET_LOADED, data);
  },
  setTerminalMouseCursorEnabled({ commit }, isEnabled) {
    commit(mutationTypes.SET_TERMINAL_MOUSE_CURSOR_ENABLED, isEnabled);
  },
  setActiveComponent({ commit }, activeComponent) {
    commit(mutationTypes.SET_ACTIVE_COMPONENT, activeComponent);
  },
  setTicketToRebet({ commit }, ticketToRebet) {
    commit(mutationTypes.SET_TICKET_TO_REBET, ticketToRebet);
  },
  setUserIsOperator({ commit }, isOperator) {
    commit(mutationTypes.SET_USER_IS_OPERATOR, isOperator);
  },
  setProductUuid({ commit }, uuid) {
    commit(mutationTypes.SET_PRODUCT_UUID, uuid);
  },
};
