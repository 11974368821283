<template>
  <div
    class="tab-button"
    :class="[{ active: isSelected }, buttonType, { mobile: icon }]"
    :id="type"
    @click="onClick"
  >
    <button :class="{ active: isSelected, disabled: isDisabled }" :disabled="isDisabled">
      <i v-if="icon" :class="icon" class="icon"></i>
      <span v-else>{{ label }}</span>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  emits: ['click'],
  props: {
    label: {
      type: String,
      default: 'Tab',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'flat',
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: [String, Number],
      default: '',
    },
    counter: {
      type: Number,
      default: 0,
    },
    isCounterActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
    animateCounter() {
      const counter = document.getElementsByClassName('counter')[0];
      if (counter) {
        counter.classList.add('animate');
        setTimeout(() => {
          counter.classList.remove('animate');
        }, 500);
      }
    },
    animateTab() {
      const tabElem = document.getElementById(this.type).childNodes[0];
      tabElem.classList.add('animate');
      setTimeout(() => {
        tabElem.classList.remove('animate');
      }, 834);
    },
  },
  watch: {
    counter(newValue, oldValue) {
      if (newValue > oldValue) {
        this.animateTab();
        this.$nextTick(() => {
          this.animateCounter();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../styles/animations.scss';

.tab-button {
  font-family: 'Roboto', 'sans-serif';
  width: 100%;
  height: 40px;
  background-color: var(--card);
  border-bottom: 2px solid rgba(#ffffff, 0.05);
  &.active {
    border-bottom: 2px solid var(--secondary);
  }
  &.mobile {
    width: 144px;
    .icon {
      font-size: 16px;
    }
  }
}

.rounded {
  padding: 5px;

  button {
    font-weight: 500;
  }
}
button {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', 'sans-serif';
  font-weight: normal;
  font-size: 14px;
  background-color: var(--card);
  color: var(--text-primary-1);
  border: 0;
  outline: 0;
  margin: 0;
  transition: all .5s ease;
  position: relative;
  &.animate {
    background-color: rgba(255,193,5,0);
    animation-name: animateButton;
    animation-duration: 0.834s;
  }
  .counter {
    position: absolute;
    background-color: var(--secondary);
    color: var(--text-secondary-1);
    font-size: var(--font-size-small, 12px);
    width: 16px;
    height: 16px;
    span {
      float: left;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-secondary-1);
      font-size: 10px;
      font-weight: bold;
    }
  }
  span + .counter {
    top: 11px;
    right: 8px;
    border-radius: 2px;
  }
  .icon + .counter {
    top: 4px;
    right: 13px;
    border-radius: 50%;
  }
  &:hover {
    background-color: var(--card-hover);
  }
  &.active {
    color: var(--secondary);
    font-weight: bold;
  }
  &:active {
    background-color: var(--card-active);
  }
  &.disabled {
    color: var(--text-primary-1);
    background-color: var(--card-active);
    opacity: 0.3;
    cursor: not-allowed;
  }
}
@media (max-width: 660px) {
  .tab-button {
    max-width: unset;
  }
}
@media (pointer: coarse) {
  button {
    &:hover {
      background-color: initial;
    }
  }
}
</style>
