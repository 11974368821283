import { keys } from 'lodash';
import componentTypes from '../utility/componentTypes';

export default {
  props: {
    type: {
      type: String,
      default: componentTypes.web.name,
      required: false,
      validator(value) {
        return keys(componentTypes).indexOf(value) > -1;
      },
    },
  },
  computed: {
    types() {
      return componentTypes;
    },
    typeClass() {
      return `type-${this.type}`;
    },
  },
};
