import currentApp from '../utility/app';

const clickoutVue2 = {
  bind(el, binding) {
    const element = el;

    element.event = (event) => {
      // 8693jhh8q
      // event.stopPropagation();
      if (!element.contains(event.target)) {
        binding.value();
      }
    };

    document.addEventListener('click', element.event);
  },
  unbind(element) {
    document.removeEventListener('click', element.event);
  },
};
const clickoutVue3 = {
  mounted(el, binding) {
    const element = el;

    element.event = (event) => {
      // 8693jhh8q
      // event.stopPropagation();
      if (!element.contains(event.target)) {
        binding.value();
      }
    };

    document.addEventListener('click', element.event);
  },
  unmounted(element) {
    document.removeEventListener('click', element.event);
  },
};
const clickout = currentApp.isVue2() ? clickoutVue2 : clickoutVue3;
export default clickout;
