<template>
  <div
    class="bet"
    :class="{
      deleteAnimation: isRemoved,
      scale: isBetAppeared,
      swipedLeftAnimation: isSwipedLeft,
      swipedRightAnimation: isSwipedRight,
    }"
    ref="bet"
  >
    <div :class="{ betOverlay: isBetAppeared }" />
    <div class="overlayLock" v-if="bet.locked">
      <i class="icon icon-lock" />
    </div>
    <div class="betHeader">
      <div class="betMarket">
        {{ bet.market }}
      </div>
      <i class="icon icon-close" @click="handleRemoveBet(bet.id)"></i>
    </div >
    <div class="betBody">
      {{ bet.outcome }}
    </div>
    <div class="betFooter">
      <div class="stakeAndRound">
        <span class="betFooterItem">
          <div>
            {{ translations.general_round }} {{ bet.roundNumber }} {{ futureRoundsText(bet) }}
          </div>
          <div class="betOdds">
            {{ isOddDefined && !hideOdds ? decimalFormatNumber(bet.odds) : null }}
          </div>
        </span>
        <BetslipInput v-if="isPaymentBetAllowed"
          @change="handleBetPayment($event, bet.id, bet.combinations)"
          @fieldFocus="setActiveBetInput(bet.id)"
          :parentValue="bet.stake"
          :label="translations.general_stake"
          :isValid="bet.isValid"
          type="terminal"
          @delete="handleBetPaymentDelete(bet.id, bet.combinations)"
          class="paymentInput"
        />
        <span v-else class="betFooterItem betFooterStake">
          <span>{{ translations.general_stake }}</span>
          <span class="betStake">{{ bet.stake.toFixed(2) }}</span>
        </span>
      </div>
      <div
        class="perBetFutureRounds"
        :style="{ display: isFuturePerBetAllowed && !isTerminal ? 'flex' : 'none' }">
        <Select
          @change="handleBetFutureRounds($event, bet.id)"
          :label="translations.general_future_bet"
          orientation="top"
          :value="bet.numEvents"
          :options="getMaxPerBetFutureRounds(bet.maxPerBetFutureRounds)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { range, isNil } from 'lodash';
import eventBus from '../../../utility/eventBus';
import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import Select from '../../Select/Select';
import { typeable } from '../../../mixins/index';

export default {
  name: 'BetslipBetSingle',
  components: {
    BetslipInput,
    Select,
  },
  mixins: [
    typeable,
  ],
  data() {
    return {
      isBetAppeared: false,
      isRemoved: false,
      isSwipedRight: false,
      isSwipedLeft: false,
      removeTimeoutId: 0,
      betAppeardTimeoutID: 0,
      stakeBoxTimeoutId: 0,
    };
  },
  props: {
    bet: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['config', 'decimalFormatNumber', 'futureRounds', 'minBetPayment']),
    ...mapGetters(['translations', 'isDesktop']),
    isFuturePerBetAllowed() {
      return this.config.isFuturePerBetAllowed;
    },
    isPaymentBetAllowed() {
      return this.config.isPaymentBetAllowed;
    },
    isOddDefined() {
      return !isNil(this.bet.odds);
    },
    isTerminal() {
      return this.type === 'terminal';
    },
    hideOdds() {
      return this.bet.hideOdds;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'betPaymentDelete',
      'removeBet',
      'setBetPayment',
      'setFutureRoundsPerBet',
      'setIsStakeBoxVisible',
      'setActiveBetslipInput',
    ]),
    getMaxPerBetFutureRounds(max) {
      return range(1, max + 1);
    },
    futureRoundsText(bet) {
      if (this.futureRounds > 1) {
        return ` - ${bet.roundNumber + bet.numEvents - 1}`;
      }

      if (bet.numEvents > 1) {
        return ` - ${bet.roundNumber + bet.numEvents - 1}`;
      }

      return '';
    },
    handleBetPayment(betStake, betId, combinations) {
      if (Number(this.bet.stake) === Number(betStake)) return;

      this.setBetPayment({ betStake, betId, combinations });
    },
    handleBetFutureRounds(futureRounds, betId) {
      this.setFutureRoundsPerBet({ futureRounds, betId });
    },
    handleBetPaymentDelete(betId, combinations) {
      this.betPaymentDelete({ betId, combinations });
    },
    handleRemoveBet(betId) {
      this.isRemoved = true;

      if (this.removeTimeoutId) clearTimeout(this.removeTimeoutId);

      this.removeTimeoutId = setTimeout(() => {
        this.removeBet(betId);
        this.isRemoved = false;
        eventBus.$emit('BetRemoved');
      }, 250);
    },
    openStakeBox() {
      if (this.stakeBoxTimeoutId) clearTimeout(this.stakeBoxTimeoutId);

      this.stakeBoxTimeoutId = setTimeout(() => {
        this.setIsStakeBoxVisible(true);
      }, 200);
    },
    setActiveBetInput(betId) {
      this.setActiveBetslipInput(betId);
      this.openStakeBox();
    },
  },
  mounted() {
    this.isBetAppeared = true;

    this.$refs.bet.scrollIntoView({ behavior: 'smooth' });

    this.betAppeardTimeoutID = setTimeout(() => {
      this.isBetAppeared = false;
    }, 600);
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
@use '../../../styles/animations';

.bet {
  min-height: 122px;
  padding: 8px;
  position: relative;
  background-color: var(--card);
  touch-action: pan-y !important;

  &.scale {
    animation-name: scale;
    animation-duration: 0.6s;
    transition-timing-function: ease;
  }

  &.swipedRightAnimation {
    position: relative;
    animation-name: swipedRight;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &.swipedLeftAnimation {
    position: relative;
    animation-name: swipedLeft;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .betOverlay {
    animation-name: betAppear;
    animation-duration: 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition-timing-function: ease;
  }

  .overlayLock {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-lock);
    z-index: 1;

    .icon-lock {
      font-size: 36px;
      color: var(--text-primary-2);
    }
  }

  .betHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    height: 21px;

    .icon-close {
      cursor: pointer;
      font-size: 24px;
      color: var(--text-primary-2);
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 2;
    }

    .betMarket {
      line-height: 20px;
      font-size: 16px;
      color: var(--secondary)
    }
  }

  .betFooter {
      .stakeAndRound {
        display: flex;

        .betOdds {
          font-weight: 700;
          color: var(--text-primary-1);
        }

        .betFooterItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 400;
          background-color: var(--card-section);
          padding: 11px 8px;
          width: 100%;
          height: 54px;
          border-radius: 2px;
          margin-right: 1px;
          font-size: 18px;
          color: var(--text-primary-1);

          &:last-child {
            margin-right: 0px;
          }

          &.betFooterStake {
            justify-content: space-between;

            .betStake {
              font-weight: 700;
            }
          }
        }

        .betStake {
          font-weight: 700;
        }

        ::v-deep .betslip-input-wrapper {
            width: 140px;
        }
      }

      .perBetFutureRounds {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;

        ::v-deep .select  {
          width: auto;
          height: auto;
          line-height: unset;

          .items {
            line-height: 40px;
            bottom: 22px;
            max-height: 100px;
          }

          .select-input {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            font-size: 12px;

            .label {
              position: static;
              margin-right: 4px;
            }

            .value {
              position: static;
              margin-right: 4px;
            }

            .icon {
              position: static;
            }
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }

  .betBody {
    font-size: 16px;
    color: var(--text-primary-1);
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 20px;
  }
}

.deleteAnimation {
  animation-name: delete;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}

@media only screen and (min-width: 1910px) {
  .bet {
    .betHeader {
      .betMarket {
        font-size: 20px;
        line-height: 22px;
      }

      .icon-close {
        font-size: 32px;
      }
    }

    .betBody {
      font-size: 20px;
      line-height: 28px;
    }

    .betFooter {
      .stakeAndRound {
        .betFooterItem {
          font-size: 20px;
          height: 72px;
        }
      }
    }
  }
}
</style>
