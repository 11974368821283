<template>
  <div class="betslipBFooter" :class="tabletClass">
    <div class="betslipBFooterStake">
      <BetslipInput
        class="betslipInput"
        :class="{ disabled: isPayinInProgress}"
        :disabled="isPayinInProgress"
        :label="translations.general_total_stake"
        :parentValue="totalPayment"
        labelPosition="left"
        @change="handleTotalPaymentChange"
        @delete="handleTotalPaymentDelete"
        @fieldFocus="setActiveTotalStakeInput"
      />
    </div>
    <div class="betslipBActions">
      <div
        v-if="isAddButtonActive"
        class="betslipBAddButton"
        :class="{ betslipBAddButtonMobile: !isDesktop }"
      >
        <Button
          @change="addBetToBetslip"
          :label="addBetLabel"
          :disabled="isAddButtonDisabled"
        >
          <i v-if="!isDesktop" class="icon icon-plus"></i>
        </Button>
      </div>
      <div class="betslipButton">
        <BetslipButton :disabled="isPayinDisabled" />
      </div>
      <div
      class="betslipOpenButton"
      :class="{
        hasBets: hasBets && !isBetslipOpen,
        payinInProgress: isPayinInProgress && hasBets && !isBetslipOpen
      }"
      >
        <Button
          :disabled="isPayinInProgress"
          @change="toggleOpen"
          label=""
          :class="{ betAdd: isBetAddedOrRemoved && !isBetslipOpen }"
        >
          <i v-if="isBetslipOpen" class="icon icon-arrow-down-a"></i>
          <div v-else>
            <i class="icon icon-betslip" />
            <span v-if="hasBets" class="ticketLength">{{ this.ticket.length }}</span>
          </div>
          <div :class="{openButtonOverlay: isOpenButtonOverlayActive && !isBetslipOpen }"></div>
        </Button>
      </div>
    </div>
    <FadeTransition>
      <BetslipStakeBox v-if="isStakeBoxActiveMobile" />
    </FadeTransition>
    <Modal v-if="isStakeBoxActiveTablet" width="400px" height="100%" :showHeader="false">
      <template v-slot:body>
        <BetslipStakeBox />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import Button from '../../Button';
import BetslipButton from '../BetslipA/BetslipButton';
import BetslipStakeBox from '../BetslipA/BetslipStakeBox';
import Modal from '../../Modal';
import FadeTransition from '../../FadeTransition';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipFooter',
  data() {
    return {
      isBetslipOpen: false,
      isBetAddedOrRemoved: false,
      isOpenButtonOverlayActive: false,
      numberOfBetsTimeoutId: 0,
      stakeBoxTimeoutId: null,
    };
  },
  components: {
    BetslipInput,
    Button,
    BetslipButton,
    BetslipStakeBox,
    FadeTransition,
    Modal,
  },
  emits: ['change'],
  computed: {
    ...mapGetters('gamesBetslip', [
      'isPayinButtonDisabled',
      'isPayinInProgress',
      'isStakeBoxVisible',
      'isAddButtonActive',
      'isTotalPaymentValid',
      'isPaymentValid',
      'isCantSplitActive',
      'isUserBalanceInvalid',
      'isSingle',
      'preBet',
      'totalPayment',
      'ticket',
    ]),
    ...mapGetters(['translations', 'isDesktop', 'isMobile', 'isTablet']),
    addBetLabel() {
      return !this.isDesktop ? '' : 'Add Bet';
    },
    hasBets() {
      return this.ticket.length > 0;
    },
    isAddBetDisabled() {
      return !this.isPreBetActive;
    },
    isAddButtonDisabled() {
      return this.isAddBetDisabled || !this.preBet.valid;
    },
    isPreBetActive() {
      return this.preBet.bet && this.preBet.bet.market;
    },
    isPayinDisabled() {
      return (this.isAddBetDisabled && !this.preBet.valid && !this.hasBets)
        || (!this.preBet.valid && !this.hasBets)
        || this.isPayinButtonDisabled
        || this.isPayinInProgress
        || !this.isTotalPaymentValid
        || (!this.isPaymentValid && this.isSingle)
        || this.isCantSplitActive
        || this.isUserBalanceInvalid;
    },
    isStakeBoxActiveMobile() {
      return this.isStakeBoxVisible && this.isMobile;
    },
    isStakeBoxActiveTablet() {
      return this.isStakeBoxVisible && this.isTablet;
    },
    numberOfBets() {
      return this.ticket.length;
    },
    tabletClass() {
      return this.isTablet ? 'tabletLayout' : '';
    },
  },
  watch: {
    numberOfBets() {
      if (this.isBetAddedOrRemoved && this.isOpenButtonOverlayActive) return;

      this.isBetAddedOrRemoved = true;
      this.isOpenButtonOverlayActive = true;

      this.numberOfBetsTimeoutId = setTimeout(() => {
        this.isBetAddedOrRemoved = false;
        this.isOpenButtonOverlayActive = false;
      }, 550);
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['addBet', 'setTotalPayment', 'setIsStakeBoxVisible', 'setActiveBetslipInput', 'totalPaymentDelete']),
    addBetToBetslip() {
      this.addBet(this.preBet.bet);
    },
    handleTotalPaymentChange(payment) {
      if (Number(payment) === this.totalPayment) return;

      this.setTotalPayment(payment);
    },
    toggleOpen() {
      this.isBetslipOpen = !this.isBetslipOpen;
      this.$emit('change', this.isBetslipOpen);
    },
    handleTotalPaymentDelete() {
      this.totalPaymentDelete();
    },
    openStakeBox() {
      if (this.stakeBoxTimeoutId) clearTimeout(this.stakeBoxTimeoutId);

      this.stakeBoxTimeoutId = setTimeout(() => {
        if (!this.isDesktop) {
          this.setIsStakeBoxVisible(true);
        }
      }, 200);
    },
    setActiveTotalStakeInput() {
      this.setActiveBetslipInput('_totalPayment');
      this.openStakeBox();
    },
  },
  mounted() {
    eventBus.$on('swipeDown', () => {
      this.toggleOpen();
    });
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    eventBus.$off('swipeDown');
    clearTimeout(this.numberOfBetsTimeoutId);
    clearTimeout(this.stakeBoxTimeoutId);
  },
  beforeDestroy() {
    eventBus.$off('swipeDown');
    clearTimeout(this.numberOfBetsTimeoutId);
    clearTimeout(this.stakeBoxTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
.betslipBFooter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--card);
  padding: 8px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  .betslipBFooterStake {
    margin-right: 8px;
    .betslipInput {
      ::v-deep input {
        &[disabled] {
          background-color: var(--color-checkbox-bg);
          color: var(--text-primary-3);
        }
      }
      &.disabled {
        ::v-deep label {
          color: var(--text-primary-3);
        }
      }
    }
  }

  .betslipBActions {
    display: flex;
    gap: 8px;
  }

  .betslipBFooterStake {
    width: 180px;
  }

  .betslipOpenButton {
    width: 50px;
    height: 40px;
    position: relative;

    &.hasBets {
      ::v-deep .button {
        background-color: var(--system-neutral);
        color: var(--text-secondary-1);
      }
    }
    &.payinInProgress {
      ::v-deep .button {
        background-color: hsl(45, 100%, 40.8%);
        color: var(--text-secondary-3);
      }
    }

    .betAdd {
      animation-name: add;
      animation-duration: 0.6s;
      transition-timing-function: ease;
    }

    .ticketLength {
      position: absolute;
      top: 2px;
      font-size: 12px;
      font-weight: bold;
    }

    .openButtonOverlay {
      animation-name: overlay;
      animation-duration: 0.6s;
      transition-timing-function: ease;
      border-radius: 2px;
      position: absolute;
      background-color: var(--text-primary-1);
      width: 50px;
      height: 40px;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 2;
    }
  }

  .betslipBAddButton {
    width: 110px;

    &.betslipBAddButtonMobile {
      width: 50px;
      height: 40px;
    }
  }

  .betslipButton {
    width: 110px;
  }

  &.tabletLayout {
    ::v-deep .predefinedStakesWrapper {
      bottom: 0;
    }

    ::v-deep .modal {
      .modal-body {
        padding: 0;
      }
    }
  }

  ::v-deep .predefinedStakesWrapper {
    left: 0;
    width: 100%;
    bottom: 0;
  }
}

@keyframes add {
  0% {
    transform: scale(1);
    font-weight: bold;
  }
  20% {
    transform: scale(1.1);
    font-weight: bold;
  }
  100% {
    transform: scale(1);
    font-weight: bold;
  }
}

@keyframes overlay {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}
</style>
