import {
  findIndex, merge, each, has, isNil, clone, assign,
} from 'lodash';
import { uuid } from 'vue-uuid';
import types from './mutationTypes';
import setValue from '../../../utility/setValue';

export default {
  [types.ADD_NEW_BET](state, bet) {
    setValue(bet, 'valid', true);
    setValue(bet, 'key', uuid.v4());
    setValue(bet, 'numEvents', bet.numEvents || 1);
    state.tickets[state.ticketType.type].push(bet);
  },
  [types.ADD_PRE_BET](state, payload) {
    state.selectedBet.value = payload.bet;
    state.selectedBet.valid = payload.valid;
  },
  [types.CLEAR_BETSLIP](state) {
    state.tickets[state.ticketType.type].splice(0, state.tickets[state.ticketType.type].length);
  },
  [types.DISABLE_PAYIN_BUTTON](state, payload) {
    state.isPayinBtnDisabled = payload;
  },
  [types.DISABLE_FREE_BET_BUTTON](state, payload) {
    state.isFreeBetButtonDisabled = payload;
  },
  [types.PAYMENT_MODIFIED](state, payload) {
    state.isPaymentModified = payload;
  },
  [types.REMOVE_INVALID_BETS](state) {
    const ticket = merge([], state.tickets[state.ticketType.type]);
    for (let i = 0; i < ticket.length; i += 1) {
      const betIndex = findIndex(state.tickets[state.ticketType.type], ticket[i]);
      if (ticket[i].locked) {
        state.tickets[state.ticketType.type].splice(betIndex, 1);
      }
    }
  },
  [types.REMOVE_BET](state, bet) {
    state.tickets[state.ticketType.type].splice(
      findIndex(state.tickets[state.ticketType.type], bet), 1,
    );
  },
  [types.SHOW_BETSLIP](state) {
    state.isBetslipOpened = !state.isBetslipOpened;
  },
  [types.SET_TAXES](state, payload) {
    setValue(state.config, 'taxes', payload);
  },
  [types.SET_CONFIG_VALUES](state, payload) {
    each(payload, (value, key) => {
      if (has(state.config, key)) {
        setValue(state.config, key, value);
      }
    });
  },
  [types.SET_FUTURE_NUMBER](state, payload) {
    setValue(state.config, 'futureBets', payload);
  },
  [types.SET_PLAYER_TICKETS](state, payload) {
    state.playerTickets = payload;
  },
  [types.SET_TICKETS_HISTORY](state, payload) {
    state.ticketsHistory = payload;
  },
  [types.SET_TICKET_TYPE](state, payload) {
    state.ticketType = payload;
  },
  [types.SET_TOTAL_STAKE_VALUE](state, payload) {
    state.totalPayment = payload;
  },
  // On draw games we need to update "eventValue" prop on new ball event
  [types.UPDATE_PLAYER_TICKET](state, payload) {
    const {
      bet, eventValue,
    } = payload;
    each(state.playerTickets, (ticket, i) => {
      const index = findIndex(ticket.bets, bet);
      if (index > -1) {
        setValue(state.playerTickets[i].bets[index], 'eventValue', eventValue);
      }
    });
  },
  [types.UPDATE_FUTURE](state, future) {
    state.future = future;
  },
  [types.UPDATE_STAKE](state, payment) {
    state.payment = payment;
  },
  [types.UPDATE_TICKET](state, payload) {
    const {
      bet, minPayment, payment, odd, locked, valid, round, displayId,
    } = payload;
    const ticket = state.tickets[state.ticketType.type].find(value => value.key === bet.key);
    if (!ticket) return;
    setValue(ticket, 'minPayment', minPayment || ticket.minPayment);
    setValue(ticket, 'payment', payment || ticket.payment);
    setValue(ticket, 'odd', odd || ticket.odd);
    setValue(ticket, 'locked', !isNil(locked) || locked ? locked : ticket.locked);
    setValue(ticket, 'valid', !isNil(valid) || valid ? valid : ticket.valid);
    setValue(ticket, 'round', round || ticket.round);
    setValue(ticket, 'displayId', displayId || ticket.displayId);
  },
  [types.SET_EDIT_BET](state, payload) {
    state.editBet = payload;
  },
  [types.SWITCH_TICKETS](state, activeTicketType) {
    state.tickets[state.ticketType.type] = clone(state.tickets[activeTicketType]);
  },
  [types.LAST_TICKETS_IN_PROGRESS](state, payload) {
    state.lastTicketsInProgress = payload;
  },
  [types.TICKET_PAYIN_IN_PROGRESS](state, payload) {
    state.ticketPayinInProgress = payload;
  },
  [types.SET_BETSLIP_COMPONENTS](state, payload) {
    state.betslipComponents = payload;
  },
  [types.SET_ACTIVE_BETSLIP_TAB](state, payload) {
    state.betslipActiveComponent = payload;
  },
  [types.SET_TICKET_TYPE_ACTIVE_COMPONENT](state, payload) {
    state.ticketTypeActiveComponent = payload;
  },
  [types.SET_TICKET_TYPE_COMPONENTS](state, payload) {
    state.ticketTypeComponents = payload;
  },
  [types.DISABLE_LAST_TICKETS](state, payload) {
    assign(state.betslipComponents[1], { disabled: payload });
  },
  [types.SET_BETSLIP_BLOCKERS](state, payload) {
    state.betslipBlockers = payload;
  },
  [types.SET_IS_QUICKPAY_ENABLED](state, isQuickpayEnabled) {
    state.isQuickpayEnabled = isQuickpayEnabled;
  },
  [types.ENABLE_TICKET_REBET](state, payload) {
    setValue(state.config, 'ticketRebetAllowed', payload);
  },
  [types.SET_IS_PAYMENT_VALID](state, isValid) {
    state.isPaymentValid = isValid;
  },
  [types.SET_MOBILE_VISUALIZATION_HEIGHT](state, payload) {
    state.mobileVisualizationHeight = window.innerHeight - (payload * 0);
  },
  [types.SET_BETSLIP_FOOTER_HEIGHT](state, payload) {
    state.betslipFooterHeight = payload;
  },
  [types.SET_BET_VALIDITY](state, valid) {
    state.isBetValid = valid;
  },
  [types.SET_PAYMENT_PER_BET](state, payload) {
    state.config.paymentPerBet = payload;
  },
  [types.SET_SCHEDULE_DISABLED](state, payload) {
    state.isScheduleDisabled = payload;
  },
  [types.SET_PAYMENT_PER_BET_VALUE](state, payload) {
    state.paymentPerBet = payload;
  },
  [types.SET_RESET_STAKE_AFTER_BET_REMOVE](state, payload) {
    state.resetStakeAfterBetRemove = payload;
  },
  [types.SET_FUTURE_PER_BET](state, payload) {
    state.config.futurePerBet = payload;
  },
  [types.LOCK_BETSLIP](state, payload) {
    state.isBetslipLocked = payload;
  },
};
