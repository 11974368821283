import { render, staticRenderFns } from "./LandscapeNotification.vue?vue&type=template&id=e440e402&scoped=true"
import script from "./LandscapeNotification.vue?vue&type=script&lang=js"
export * from "./LandscapeNotification.vue?vue&type=script&lang=js"
import style0 from "./LandscapeNotification.vue?vue&type=style&index=0&id=e440e402&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e440e402",
  null
  
)

export default component.exports