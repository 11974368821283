<template>
  <div
      class="comboWrapper"
      ref="bet"
      :class="{
        deleteAnimation: isRemoved,
        scale: isBetAppeared,
        swipedLeftAnimation: isSwipedLeft,
        swipedRightAnimation: isSwipedRight,
      }"
      v-horizontalSwipe="swipeHandlers"
    >
    <div :class="{ betOverlay: isBetAppeared }" />
    <div class="overlayLock" v-if="bet.locked">
      <i class="icon icon-lock" />
    </div>
    <div class="marketWrapper">
      <div class="market">{{ bet.market }}</div>
       <i class="icon icon-close" @click="handleRemoveBet(bet.id)"></i>
    </div>
    <div class="eventName">{{ bet.eventName }}</div>
    <div class="footer">
      <div class="outcome">{{ bet.outcome }}</div>
      <div class="odds">{{ oddsConverter(bet.odds) }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import horizontalSwipe from '../../../directives/horizontalSwipe';
import oddConverter from '../../../utility/oddConverter';

export default {
  name: 'BetslipBetCombo',
  props: {
    bet: {
      type: Object,
    },
  },
  data() {
    return {
      isBetAppeared: false,
      isRemoved: false,
      isSwipedRight: false,
      isSwipedLeft: false,
      removeTimeoutId: 0,
      betAppeardTimeoutID: 0,
      swipeHandlers: {
        left: this.handleSwipeLeft,
        right: this.handleSwipeRight,
      },
    };
  },
  computed: {
    ...mapGetters('gamesBetslip', ['isBetAppearAnimationDisabled']),
    ...mapGetters(['isDesktop', 'oddType']),
  },
  directives: {
    horizontalSwipe,
  },
  methods: {
    ...mapActions('gamesBetslip', ['removeBet', 'setIsBetAppearAnimationDisabled']),
    handleRemoveBet(betId) {
      this.isRemoved = true;

      if (this.removeTimeoutId) clearTimeout(this.removeTimeoutId);

      this.removeTimeoutId = setTimeout(() => {
        this.removeBet(betId);
        this.isRemoved = false;
      }, 250);
    },
    handleSwipeLeft() {
      this.removeBetLeft();
    },
    handleSwipeRight() {
      this.removeBetRight();
    },
    removeBetLeft() {
      if (!this.isDesktop) {
        this.isSwipedLeft = true;
        setTimeout(() => {
          this.removeBet(this.bet.id);
          this.isSwipedLeft = false;
        }, 250);
      }
    },
    removeBetRight() {
      if (!this.isDesktop) {
        this.isSwipedRight = true;
        setTimeout(() => {
          this.removeBet(this.bet.id);
          this.isSwipedRight = false;
        }, 250);
      }
    },
    oddsConverter(odd) {
      return oddConverter(odd, this.oddType);
    },
  },
  mounted() {
    if (!this.isBetAppearAnimationDisabled) {
      this.isBetAppeared = true;

      this.$refs.bet.scrollIntoView({ behavior: 'smooth' });

      this.betAppeardTimeoutID = setTimeout(() => {
        this.isBetAppeared = false;
      }, 600);
    }

    this.$nextTick(() => {
      this.setIsBetAppearAnimationDisabled(false);
    });
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
@use '../../../styles/animations';

.comboWrapper {
  background-color: var(--card);
  height: 92px;
  padding: 8px;
  position: relative;

  .marketWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .market {
      color: var(--secondary);
      font-size: 14px;
      line-height: 18px;
    }

    .icon-close {
      font-size: 20px;
      color: var(--text-primary-2);
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .eventName {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px;
    line-height: 18px;
  }

  .footer {
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-section);
    padding: 6px 8px;
    height: 30px;

    .odds {
      font-weight: 700;
    }
  }

  &.scale {
    animation-name: scale;
    animation-duration: 0.6s;
    transition-timing-function: ease;
  }

  &.swipedRightAnimation {
    position: relative;
    animation-name: swipedRight;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &.swipedLeftAnimation {
    position: relative;
    animation-name: swipedLeft;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .betOverlay {
    animation-name: betAppear;
    animation-duration: 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition-timing-function: ease;
  }

  .overlayLock {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-lock);

    .icon-lock {
      font-size: 36px;
      color: var(--text-primary-2);
    }
  }
}

.deleteAnimation {
  animation-name: delete;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}
</style>
