import currentApp from '../utility/app';
import convert from '../utility/convert';

const getPropertyValue = (element, property, pseudoElement = null) => window
  .getComputedStyle(element, pseudoElement)
  .getPropertyValue(property);

const getStates = ([h, s, l]) => {
  const hover = `hsl(${h}, ${s}%, ${l + 5}%)`;
  const active = `hsl(${h}, ${s}%, ${l - 5}%)`;

  return [hover, active];
};

const createStates = (element, pseudoElement = null) => {
  const backgroundColor = getPropertyValue(element, 'background-color', pseudoElement)
    ?.match(/\d+/g)
    ?.map(Number);

  if (backgroundColor) {
    const [r, g, b] = backgroundColor;

    const [hover, active] = getStates(convert.RGBToHSL(r, g, b));

    element.style.setProperty('--hover-state', hover);
    element.style.setProperty('--active-state', active);
  }
};
const interactionStatesVue2 = {
  inserted(element) {
    createStates(element, '::after');
  },
  update(element) {
    createStates(element, '::after');
  },
};
const interactionStatesVue3 = {
  mounted(element) {
    createStates(element, '::after');
  },
  updated(element) {
    createStates(element, '::after');
  },
};
const interactionStates = currentApp.isVue2() ? interactionStatesVue2 : interactionStatesVue3;
export default interactionStates;
