import isMobile from 'ismobilejs';
import types from './mutationTypes';
import { streamMapping } from '../api';

export default {
  setFullscreenStatus({ commit }, fullScreen) {
    commit(types.SET_FULL_SCREEN_STATUS, fullScreen);
  },
  async getStreamUuid({ commit }, { env, tenantId }) {
    const { data } = await streamMapping.getStreamUuid(env, tenantId);
    commit(types.SET_STREAM_UUID, data);
  },
  toggleAudio({ commit }) {
    commit(types.TOGGLE_AUDIO);
  },
  setDeviceLayout({ commit }) {
    const isMobileDeviceLayout = isMobile().phone;
    const isTabletDeviceLayout = isMobile().tablet;
    const isDesktopDeviceLayout = !isMobile().phone && !isMobile().tablet;
    commit(types.SET_IS_MOBILE_DEVICE_LAYOUT, isMobileDeviceLayout);
    commit(types.SET_IS_TABLET_DEVICE_LAYOUT, isTabletDeviceLayout);
    commit(types.SET_IS_DESKTOP_DEVICE_LAYOUT, isDesktopDeviceLayout);
  },
  setOddType: ({ commit }, payload) => {
    commit(types.SET_ODD_TYPE, payload);
  },
};
