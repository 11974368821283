<template>
  <div class="input-container"
       :class="[typeClass, { disabled: disabled }]">
    <label :class="orientation"
           v-if="labelActive">
      {{label}}
    </label>
    <input class="input"
           :class="[orientation]"
           v-on="inputListeners"
           :disabled="disabled"
           :value="inputValue"
           :name="name"
           @focus="shouldSelectText && $event.target.select()"
           @blur="updateValueOnBlur"
           @keydown.tab.enter="$emit('tabEnterEmit', $event)"
           @keydown="checkEvent"
           @click="isChanged = true;$emit('fieldFocus')"
           :style="{'text-align': orientation}"
           :type="inputType"
           :readonly="readonly"
           :onpaste="onPaste"
           autocomplete="off">
    <i class="icon"
       :class="icon"
       @click="$emit('click')">
    </i>
  </div>
</template>

<script>
import { assign, isNil } from 'lodash';
import { disablable, typeable } from '../mixins';

export default {
  name: 'Input',
  data() {
    return {
      isChanged: false,
    };
  },
  props: {
    name: {
      default: 'inputField',
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    inputType: {
      default: 'number',
      type: String,
    },
    value: {
      default: null,
      type: [String, Number],
    },
    orientation: {
      default: 'left',
      type: String,
    },
    round: {
      type: Number,
      default: null,
    },
    labelActive: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '', // example => icon-backspace-a
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    showZeroOnInput: {
      type: Boolean,
      default: false,
    },
    shouldUpdateValueOnBlur: {
      type: Boolean,
      default: true,
    },
    shouldSelectText: {
      type: Boolean,
      default: true,
    },
    allowPaste: {
      type: Boolean,
      default: true,
    },
    shouldRound: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'tabEnterEmit', 'fieldFocus'],
  mixins: [
    disablable,
    typeable,
  ],
  methods: {
    updateValue(event) {
      const value = event.target.value || this.min;
      this.$emit('change', value);
    },
    checkEvent(event) {
      switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
        case 'Left': // IE/Edge specific value
        case 'ArrowLeft':
        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
          this.isChanged = true;
          break;
        case 'Enter':
        case 'Tab':
        case 'Backspace':
          break;
        default:
          if (this.type === 'retail' || this.type === 'web') {
            this.checkNumbers(event);
          }
      }
    },
    checkNumbers(event) {
      const key = event.key.replace(',', '.');
      const { selectionStart, selectionEnd } = event.target;
      let input;
      if (selectionStart === selectionEnd) {
        input = `${event.target.value.slice(0, selectionStart)}${key}${event.target.value.slice(selectionStart)}`;
      } else {
        const intoArray = event.target.value.split('');
        const remove = selectionEnd - selectionStart;
        intoArray.splice(selectionStart, remove, key);
        input = intoArray.join();
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/,/g, '.');
      const regexExp = new RegExp(`^([,\\d]+)?\\d+(?:\\.\\d{0,${this.round}})?$`, 'g');
      const isValid = input.match(regexExp);
      if (!isValid) {
        event.preventDefault();
        this.isChanged = true;
      }
    },
    updateValueOnBlur(event) {
      if (this.shouldUpdateValueOnBlur) {
        if (this.name === 'inputPaymentPerBet') {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '';
        }
        this.isChanged = false;
        let value = +event.target.value ? event.target.value : this.min;
        if (this.showZeroOnInput && Number(this.value) === 0) {
          value = Number(this.value).toFixed(this.round);
        }
        // eslint-disable-next-line no-param-reassign
        event.target.value = !value ? value : Number(value).toFixed(this.round);
      }
    },
  },
  computed: {
    onPaste() {
      return `return ${this.allowPaste}`;
    },
    inputValue() {
      if (this.round && !isNil(this.value) && this.shouldRound) {
        return Number(this.value).toFixed(this.round);
      }
      return this.value;
    },
    inputListeners() {
      const vm = this;

      // We use this specifically for v-calendar library
      // v-calendar has multiple listeners and one of them is change
      // In order to not overwrite our own change listener we need to
      // call both change listeners in one change listener
      return assign({}, this.$listeners, {
        change(event) {
          vm.updateValue(event);
        },
        click(event) {
          if (vm.shouldSelectText) {
            event.target.select();
          }
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  position: relative;
  display: flex;
  width: var(--input-width, 150px);
  height: 40px;

  label {
    position: absolute;
    top: 5px;
    font-size: 12px;
    opacity: 0.6;
    color: var(--text-primary-2);

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }
  .icon {
    position: absolute;
    right: 16px;
    top: calc((var(--input-height, 40px) / 2) - 6px);
    cursor: pointer;
    color: var(--text-primary-1);
  }

  &.type-terminal {
    height: 72px;

    label {
      font-size: 18px;
      top: 15px;
    }
    .input {
      font-size: 24px;
      padding: 28px 0 0 10px;
    }
    .icon {
      font-size: 24px;
      top: calc((72px / 2) - 10px)
    }
  }
}
.input {
  font-family: "Roboto", sans-serif;
  height: 100%;
  width: 100%;
  background-color: var(--input);
  border-radius: var(--input-border-radius, 2px);
  font-size: 14px;
  border: none;
  color: var(--text-primary-1);
  padding: var(--input-padding, 15px 0 0 10px);

  &.left {
    padding: var(--input-padding, 15px 0 0 10px);
  }

  &.right {
    padding: var(--input-padding, 15px 10px 0 0);
  }

  &:focus {
    outline: 0;
    border: 0;
  }
  // todo :hover, active...
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
