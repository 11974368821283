(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vuex"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["vuex", "vue"], factory);
	else if(typeof exports === 'object')
		exports["games"] = factory(require("vuex"), require("vue"));
	else
		root["games"] = factory(root["Vuex"], root["Vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__38714__, __WEBPACK_EXTERNAL_MODULE__35594__) {
return 