import types from './mutationTypes';

export default {
  [types.REGISTER_CLIENT_APP](state, app) {
    state.clientApp = app;
  },
  [types.SET_FULL_SCREEN_STATUS](state, fullScreen) {
    state.isFullScreen = fullScreen;
  },
  [types.SET_STREAM_UUID](state, uuid) {
    state.streamUuid = uuid;
  },
  [types.TOGGLE_AUDIO](state) {
    state.isAudioOn = !state.isAudioOn;
  },
  [types.SET_IS_MOBILE_DEVICE_LAYOUT](state, isMobileDeviceLayout) {
    state.isMobile = isMobileDeviceLayout;
  },
  [types.SET_IS_TABLET_DEVICE_LAYOUT](state, isTabletDeviceLayout) {
    state.isTablet = isTabletDeviceLayout;
  },
  [types.SET_IS_DESKTOP_DEVICE_LAYOUT](state, isDesktopDeviceLayout) {
    state.isDesktop = isDesktopDeviceLayout;
  },
  [types.SET_ODD_TYPE](state, payload) {
    state.oddType = payload;
  },
};
