<template>
  <div class="last-tickets-actions">
    <div class="action-item">
      <component :is="tooltipComponent"
        :text="translations.general_active_tickets"
        :show="lastTicketsTooltip">
        <i class="icon icon-refresh-a"
           @touchstart="showTooltip('lastTicketsTimer', 'lastTicketsTooltip')"
           @touchend="hideTooltip('lastTicketsTimer', 'lastTicketsTooltip')"
           @mousedown="showTooltip('lastTicketsTimer', 'lastTicketsTooltip')"
           @mouseup="hideTooltip('lastTicketsTimer', 'lastTicketsTooltip')"
           @click="refreshTicketList">
        </i>
      </component>
    </div>
    <div class="action-item">
      <component :is="tooltipComponent"
        :text="translations.general_ticket_history"
        :show="ticketHistoryTooltip">
        <i class="icon icon-history"
           @touchstart="showTooltip('ticketHistoryTimer', 'ticketHistoryTooltip')"
           @touchend="hideTooltip('ticketHistoryTimer', 'ticketHistoryTooltip')"
           @mousedown="showTooltip('ticketHistoryTimer', 'ticketHistoryTooltip')"
           @mouseup="hideTooltip('ticketHistoryTimer', 'ticketHistoryTooltip')"
           @click="openTicketHistory"></i>
      </component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';
import { eventBus, currentApp } from '../../../utility';


export default {
  name: 'LastTicketsActions',
  components: {
    Tooltip: () => import(/* webpackChunkName: "components.tooltip" */'../../Tooltip'),
    Tooltipv3: defineAsyncComponent(() => import(/* webpackChunkName: "components.tooltipv3" */'../../Tooltipv3')),
  },
  data() {
    return {
      lastTicketsTooltip: false,
      ticketHistoryTooltip: false,
      timers: {
        lastTicketsTimer: null,
        ticketHistoryTimer: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'translations',
    ]),
    ...mapGetters({
      user: 'user/user',
      isLoggedIn: 'user/isLoggedIn',
    }),
    tooltipComponent() {
      return currentApp.isVue2() ? 'Tooltip' : 'Tooltipv3';
    },
  },
  methods: {
    showTooltip(timer, tooltip) {
      this.timers[timer] = setTimeout(() => {
        this[tooltip] = true;
      }, 1000);
    },
    hideTooltip(timer, tooltip) {
      clearTimeout(this.timers[timer]);
      this[tooltip] = false;
    },
    refreshTicketList() {
      if (this.user.profile.logged) {
        eventBus.$emit('LoadLastTickets');
      }
    },
    openTicketHistory() {
      if (this.user.profile.logged) {
        eventBus.$emit('openTicketHistory');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.last-tickets-actions {
  display: flex;
  height: 40px;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--background-2);
  text-align: center;

  .action-item {
    line-height: 40px;
    cursor: pointer;
    color: var(--text-primary-2, rgba(239, 239, 239, 0.6));
    height: 40px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: block;
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s ease;
      &:disabled,
      &.disabled {
        color: var(--text-primary-3, rgba(239, 239, 239, 0.3));
      }
      &:hover {
        color: var(--text-primary-1);
        transition: all 0.3s ease;
      }
    }
  }
}
</style>
