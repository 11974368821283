import currentApp from '../utility/app';

let startX;
let startY;
const MIN_SWIPE_DISTANCE = 100;


const handleTouchStart = (event) => {
  startX = event.touches[0].clientX;
  startY = event.touches[0].clientY;
};

const handleTouchEnd = value => (event) => {
  const endX = event.changedTouches[0].clientX;
  const endY = event.changedTouches[0].clientY;
  const deltaX = endX - startX;
  const deltaY = endY - startY;
  if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) >= MIN_SWIPE_DISTANCE) {
    if (deltaX > 0) {
      // eslint-disable-next-line no-unused-expressions
      value.right && value.right();
    } else {
      // eslint-disable-next-line no-unused-expressions
      value.left && value.left();
    }
  }
};

const horizontalSwipeVue2 = {
  bind(element, binding) {
    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchend', handleTouchEnd(binding.value));
  },
  unbind(element, binding) {
    element.removeEventListener('touchstart', handleTouchStart);
    element.removeEventListener('touchend', handleTouchEnd(binding.value));
  },
};


const horizontalSwipeVue3 = {
  beforeMount(el, binding) {
    el.addEventListener('touchstart', handleTouchStart);
    el.addEventListener('touchend', handleTouchEnd(binding.value));
  },
  unmounted(el, binding) {
    el.removeEventListener('touchstart', handleTouchStart);
    el.removeEventListener('touchend', handleTouchEnd(binding.value));
  },
};

const horizontalSwipe = currentApp.isVue2() ? horizontalSwipeVue2 : horizontalSwipeVue3;
export default horizontalSwipe;
