export default {
  /**
   * Script that will register global google site tag and if provided with code google tag manager.
   * @param {string} [auth='']
   * @param {string} [code='']
   * @param {string} [preview='']
   * @returns {Void}
   */
  setup(code = '', auth = '', preview = '') {
    const googleScript = document.createElement('script');
    googleScript.async = true;
    googleScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-BHG644VXRB';
    const globalSiteTagScript = document.createElement('script');

    if (code) {
      // GOOGLE TAG MANAGER ( USED PER COMPANY FOR OTHER CLIENTS )
      const instanceNoScript = document.createElement('noscript');
      const instanceScript = document.createElement('script');
      const instanceScriptSnippet = [
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${auth}
      &gtm_preview=${preview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})
      (window,document,'script','dataLayer','${code}');`.replace(/[\n\r]+ */g, ''),
      ];

      const instanceNoScriptSnippet = [
        `<iframe src="https://www.googletagmanager.com/ns.html?id=${code}&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      ];
      instanceScript.innerText = instanceScriptSnippet.join('');
      instanceNoScript.innerText = instanceNoScriptSnippet.join('');
      document.head.appendChild(instanceScript);
      document.body.insertBefore(instanceNoScript, document.body.childNodes[0]);
    }
    // GLOBAL GOOGLE SITE TAG ( USED INTERNALLY FOR ANALYTICS )
    const globalSiteTagScriptSnippet = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "G-BHG644VXRB");';
    globalSiteTagScript.innerText = globalSiteTagScriptSnippet;
    document.head.insertBefore(globalSiteTagScript, document.head.childNodes[0]);
    document.head.insertBefore(googleScript, document.head.childNodes[0]);
  },
};
